<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>{{ $store.state.user.locale === 'en_GB' ? 'Pupil' : 'Student' }} Groups</h2>
            <div class="box-tools pull-right">
              <button type="button" class="button" @click.prevent="addGroup">
                <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add Group</span>
              </button>
            </div>
            <p v-if="!$store.state.user.school.admin">
              N.B. Sign in with an admin account to view all groups.
            </p>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <b-table
              v-if="classData"
              :data.sync="classData"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="Name" item-width="10px">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="actions" label>
                  <div class="btn-group pull-right">
                    <router-link :to="'/groups/'+props.row.id" class="btn button is-link">
                      Details
                    </router-link>
                    <button
                      type="button"
                      class="btn button is-link dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span class="caret" />
                      <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a href="#" @click.prevent="renameGroup(props.row)">Rename</a>
                      </li>
                      <li v-if="$store.state.user.school.admin" role="separator" class="divider" />
                      <li>
                        <a
                          v-if="$store.state.user.school.admin"
                          href="#"
                          @click.prevent="removeAllPupils(props.row.id)"
                        >Remove all {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}</a>
                      </li>
                      <li>
                        <a
                          v-if="$store.state.user.school.admin"
                          href="#"
                          @click.prevent="deleteGroup(props.row.id)"
                        >Delete</a>
                      </li>
                    </ul>
                  </div>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
            <div v-else class="alert">
              <b>{{ response }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { request } from '@/edshed-common/api'
import store from '@/store'

export default {
  name: 'GroupsView',
  data () {
    return {
      classData: null,
      teachersData: null,
      response: 'Data Loading...'
    }
  },
  // Redirect to new home for non-superusers
  beforeRouteEnter (to, from, next) {
    if (store.state.user && store.state.user.superuser) {
      next()
    } else {
      let locale = 'en-gb'
      if (store.state.user && store.state.user.locale && store.state.user.locale) {
        locale = store.state.user.locale.toLowerCase().replace('_', '-')
      }
      window.location.replace(`https://www.edshed.com/${locale}/groups`)
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$store.state.user.school.org_type === 'district') {
        this.$router.push({ path: '/' })
        return
      }
      this.getListData()
    })
  },
  methods: {
    addGroup () {
      const newGroup = prompt('Enter new group name', '')
      if (newGroup != null) {
        request(
          'POST',
          'users/me/school/groups',
          { name: newGroup },
          this.$store.state.token
        )
          .then((response) => {
            const data = response.data
            console.log('new group data' + data.group)
            this.promptAddGroup(data.group)
            this.getListData()
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    promptAddGroup (group) {
      if (this.$store.state.user.school.admin) {
        const c = confirm('Add yourself as a teacher to new group?')
        if (c) {
          this.addTeacher(group.id)
        }
      } else {
        this.addTeacher(group.id)
      }
    },
    addTeacher (groupId) {
      const teacherId = this.$store.state.user.id

      request(
        'PUT',
        'users/me/school/groups/' + groupId + '/teachers',
        { teachers: teacherId },
        this.$store.state.token
      )
        .then((response) => {
          const data = response.data
          this.teachersData = data.teachers

          // console.log('readerData: ' + this.readerData)
          if (!this.teachersData || this.teachersData.length === 0) {
            this.response = 'No Teachers'
          }
        })
        .catch((error) => {
          console.log(error)

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    deleteGroup (id) {
      const confirm = window.confirm(
        'Are you sure you want to delete this group? N.B. Pupil accounts will NOT be deleted.'
      )
      if (confirm) {
        request(
          'DELETE',
          'users/me/school/groups/' + id,
          null,
          this.$store.state.token
        )
          .then((response) => {
            this.getListData()
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    removeAllPupils (id) {
      const confirm = window.confirm(
        'Are you sure you want to remove all ' + (this.$store.state.user.locale === 'en_GB' ? 'pupils' : 'students') + ' from this group? N.B. ' + (this.$store.state.user.locale === 'en_GB' ? 'Pupil' : 'Student') + ' accounts will NOT be deleted.'
      )
      if (confirm) {
        request(
          'DELETE',
          'users/me/school/groups/' + id + '/pupils',
          { pupils: '*' },
          this.$store.state.token
        )
          .then((response) => {
            this.getListData()
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    renameGroup (group) {
      const newGroup = prompt('Enter new group name', group.name)
      if (newGroup != null) {
        request(
          'PUT',
          'users/me/school/groups/' + group.id,
          { name: newGroup },
          this.$store.state.token
        )
          .then((response) => {
            this.getListData()
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    getListData () {
      request('GET', 'users/me/school/groups', null)
        .then((response) => {
          const data = response.data
          this.classData = data.groups

          // console.log('readerData: ' + this.readerData)
          if (!this.classData || this.classData.length === 0) {
            this.response = 'No Classes'
          }
        })
        .catch((error) => {
          console.log(error)

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    }
  }
}
</script>
